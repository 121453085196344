import React, { useState } from 'react';
import { useMediaQuery } from 'react-responsive';
import CustomText from './CustomText';
import CustomButton from './CustomButton';
import GridContainer from './Grid';
import NormalContainer from './NormalContainer';
import Div100vh, { use100vh } from 'react-div-100vh';


const QuestionPage = ({language, calculate ,endText='NEXT', setAns, landscapeWidth = '85vw', portraitWidth = '77vw', question, extraInstruct, ans = [], prevPage, nextPage, selectedAns, questionNo = 1 }) => {

    const next = () => {
        if (activeButton === -1)
            return;
          if (calculate)
          {
            calculate();
          }
        nextPage();
        //alert('Button clicked!');
      };

      const prev = () => {
        prevPage();
        //alert('Button clicked!');
      };
    
    const isLandscape = useMediaQuery({ query: '(orientation: landscape)' });
    const divStyle = {

      width: '100%',
      height: '100%',
      position: 'absolute'
      
  };
  
    const backgroundImageStyle = {

      width: '100%',
      height: '100%',

    };
  
    function GetPad(){
      return isLandscape ? '7%' : '10%'
    }
  
  
    const findStyle = {
      height: isLandscape ? '8vh' : '3.8vh',
      position: 'absolute', // To position the Find SVG within the container
      top: isLandscape ? '12%' : '7%', // Vertically center the Find SVG
      right: '0%', // Place the Find SVG 10% from the left
      transform: 'translateY(-50%)', // Center the Find SVG vertically
      padding: GetPad(),
    };
  
    const [activeButton, setActiveButton] = useState(selectedAns);
    const handleClick = (i) => {
        setActiveButton(i);
        setAns(questionNo, i);
    };
  
    function GetButTextSize(){
      return isLandscape ? '3vh' : '2vh'
    }

    function GetPrev(){
      if (language == "ENG"){
        return "PREV";
      }
      else if (language == "JP"){
        return "戻る";
      }
      else if (language == "KR"){
        return "이전";
      }
      else if (language == "ZH"){
        return "上一頁";
      }
      else if (language == "TH"){
        return "ก่อนหน้า";
      }
      return "PREV";
    }

    function GetNext(){
      if (language == "ENG"){
        if (questionNo == 10){
          return "RESULT"
        }
        return "NEXT";
      }
      else if (language == "JP"){
        if (questionNo == 10){
          return "結果を見る"
        }
        return "進む";
      }
      else if (language == "KR"){
        if (questionNo == 10){
          return "결과"
        }
        return "다음";
      }
      else if (language == "ZH"){
        if (questionNo == 10){
          return "結果"
        }
        return "下一頁";
      }
      else if (language === "TH"){
        if (questionNo === 10){
          return "ผลลัพธ์"
        }
        return "ถัดไป";
      }
      return "NEXT";
    }
  
    return (



      <div style={divStyle}>
        
        {/* You can add content inside this div if needed */}
        <img src={isLandscape ? '/questionbg/q'+questionNo+'-landscape.webp' : '/questionbg/q'+questionNo+'-portrait.webp'} alt="bg" style={backgroundImageStyle} />       
        <img src={`/logo/smallfind${language.toLowerCase()}.webp`} alt="Find SVG" className="find-svg" style={findStyle} />

<NormalContainer left={GetPad()} top='25%'>
<CustomText position='static' maxWidth={isLandscape ? '75vw' : '80vw'} fontWeight='bold' font={language} text={question} fontSize={isLandscape ? (language=='ENG'? '4.2vh' : '3.2vh' ) : (language=='ENG'? '3vh' : '2.5vh' )} left={GetPad()} top={isLandscape ? '25%' : '35%'}/>
        <CustomText position='static' font={language} text={extraInstruct} fontSize={isLandscape ? (language=='ENG'? '2.5vh' : '2vh' ) : (language=='ENG'? '2vh' : '1.5vh' )} left={GetPad()} top={isLandscape ? '27%' : '37%'}/>
</NormalContainer>
    
        {/* Render a CustomButton for each element in the 'ans' array */}

        <GridContainer display='inline' rows='auto auto auto auto' width={isLandscape ? landscapeWidth : portraitWidth}    top={isLandscape ? '35%' : '38%'} left={GetPad() } gapX={isLandscape ? '4vw' : '10vw'} gapY={isLandscape ? '4vw' : '2vh'}>      


        
        {ans.map((answer, index) => (
  <React.Fragment key={index}>
    <CustomButton
      onClick={() => handleClick(index)}
      isActive={activeButton === index}
      margin={isLandscape ? '2vh 2vh 2vh 0vh' : '1vh 1vh 1vh 0vh'}
      padding={isLandscape ? '0.5vh 2vh 0.5vh 2vh' : '0.5vh 2vh 0.5vh 2vh'}
      textAlign='left'
      fontSize={GetButTextSize()}
      font={language}
    >
      {answer}
    </CustomButton>
    <br />
  </React.Fragment>
))}

</GridContainer>

<CustomText onClick={prev}   font={language} text={GetPrev()} fontSize={isLandscape ? '3vh' : '2vh'} left={GetPad()} top={isLandscape? '85%' : '90%'}/>
  
<CustomText transform='translateX(-50%)'  font={language} text={questionNo + '/10'} fontSize={isLandscape ? '3vh' : '2vh'} left='50%' top={isLandscape? '85%' : '90%'}/>
  
<CustomText onClick={next}  textAlign='right'   font={language} text={GetNext()} fontSize={isLandscape ? '3vh' : '2vh'} right={GetPad()} top={isLandscape? '85%' : '90%'}/> 
      </div>


      
    );
  };
  
  export default QuestionPage;