import React from 'react';
import './fonts.css';

function TextWithLine({ text, font, fontSize }) {
  const containerStyle = {
    display: 'flex',
    alignItems: 'center',
  };

  const textStyle = {
    paddingTop: font === 'ENG' ? '0.5vh' : '0', // Apply the yOffset to control the vertical position of the span
    fontFamily: font,
    fontSize : fontSize,
    marginRight: '10px', // Adjust the spacing between text and line as needed
    color: 'white', // Add this line to specify the text color
  };

  const lineStyle = {
    flex: 1,
    height: '1px',
    backgroundColor: 'transparent',
  };

  const realLineStyle = {
    width: '30%',
    height: '100%',
    backgroundColor: 'white',
  };

  return (
    <div style={containerStyle}>
      <span style={textStyle}>{text}</span>
      <div style={lineStyle}>
        <div style={realLineStyle}>
        
        </div>
      </div>
    </div>
  );
}

export default TextWithLine;
