import React from 'react';
import './fonts.css';

const SwiperText = ({opacity = '1', color='white', marginRight="0px", horizontalPos = 'center', width='auto', height='auto', paddingLeft = '0px', font, text = '', fontSize, fontWeight = 'normal'}) => {
  const style = {
    width:'100%',
    height:'100%',
    fontWeight: fontWeight,
    fontFamily: font,
    fontSize: fontSize || '3vh',
    color: color,
    display: 'inline-flex',
    alignItems: 'center',
    userSelect: 'none',
    justifyContent: horizontalPos,
    paddingTop: font === "ENG" ? '0.7vh' : '0px',
    paddingBottom: font === "ENG" ? '0vh' : '0.5vh',
    marginRight: marginRight,
    opacity : opacity,
  };

  // Replace '\n' with <br /> tags


  return (
    <span style={style}>
      {text}
    </span>
  );
};

export default SwiperText;
