import React from 'react';
import { useMediaQuery } from 'react-responsive';
import CustomText from './CustomText';
import CustomButton from './CustomButton';
import GridContainer from './Grid';
import Div100vh, { use100vh } from 'react-div-100vh';

const StartPage = ({ setLanguage,  toIntroPage }) => {
  const isLandscape = useMediaQuery({ query: '(orientation: landscape)' });


  const divStyle = {

      width: '100%',
      height: '100%',
      position: 'absolute'
      
  };

  const backgroundImageStyle = {

    width: '100%',
    height: '100%',

  };
  

  function GetPad(){
    return isLandscape ? '7%' : '10%'
  }

  const svgStyle = {
    height: isLandscape ? '5vh' : '3vh',
    position: 'absolute', // To position the SVG within the container
    top: GetPad(), // Vertically center the SVG
    left: GetPad(), // Horizontally center the SVG
    //padding: GetPad(),
  };

  const findStyle = {
    width: isLandscape ? 'auto' : '80vw', // Set the SVG width conditionally
    height: isLandscape ? '7vh' : 'auto',
    position: 'absolute', // To position the Find SVG within the container
    top: '45%', // Vertically center the Find SVG
    left: '0', // Place the Find SVG 10% from the left
    transform: 'translateY(-50%)', // Center the Find SVG vertically
    padding: GetPad(),
  };

  const handleClick = (i) => {
    setLanguage(i);
    toIntroPage();
    //alert('Button clicked!');
  };

  function GetButTextSize(){
    return isLandscape ? '3vh' : '1.8vh'
  }

  return (



    <div style={divStyle}>
      <img src={isLandscape ? '/bg1-landscape.webp' : '/bg1-portrait.webp'} alt="bg" style={backgroundImageStyle} />
      {/* You can add content inside this div if needed */}
      <img src="/lulu.svg" alt="Lulu SVG" className="lulu-svg" style={svgStyle} />
      <img src="/logo/find.webp" alt="Find SVG" className="find-svg" style={findStyle} />
      <CustomText font="ENG" text="Select your language to begin:" fontSize={isLandscape ? '4vh' : '2vh'} left={GetPad()} top="75%"/>
  <GridContainer columns={isLandscape ? 'auto auto auto auto auto' : 'auto auto auto'}  width={isLandscape ? '75vw' : '80vw'} height={isLandscape ? '6vh' : '10vh'} top={isLandscape ? '82%' : '80%'} left={GetPad() } gapX={isLandscape ? '4vw' : '5vw'} gapY={isLandscape ? '4vw' : '2vh'}>      
    <CustomButton fontSize={GetButTextSize()} onClick={()=>handleClick(0)} font="ENG">English</CustomButton>
      <CustomButton fontSize={GetButTextSize()} onClick={()=>handleClick(1)} font="KR">한국어</CustomButton>
      <CustomButton fontSize={GetButTextSize()} onClick={()=>handleClick(2)} font="JP">⽇本語</CustomButton>
      <CustomButton fontSize={GetButTextSize()} onClick={()=>handleClick(3)} font="ZH">中文</CustomButton>
      <CustomButton fontSize={GetButTextSize()} onClick={()=>handleClick(4)} font="TH">ไทย</CustomButton>
    </GridContainer>



    </div>



  );
};

export default StartPage;
