import React, { useState } from 'react';
import './App.css';
import StartPage from './Home';
import IntroPage from './IntroPage';
import { CSSTransition } from 'react-transition-group';
import QuestionPage from './QuestionPage';
import QuestionPageMultiple from './QuestionPageMultiple';
import QuestionPageBar from './QuestionPageBar';
import PlanPage from './PlanPage';
import ResultPage from './ResultPage';

let currentPageID = 0;

function App() {

  const [showHome, setShowHome] = useState(true);
  const [showIntroPage, setShowIntroPage] = useState(false);
  const [showQ1, setShowQ1Page] = useState(false);
  const [showQ2, setShowQ2Page] = useState(false);
  const [showQ3, setShowQ3Page] = useState(false);
  const [showQ4, setShowQ4Page] = useState(false);
  const [showQ5, setShowQ5Page] = useState(false);
  const [showQ6, setShowQ6Page] = useState(false);
  const [showQ7, setShowQ7Page] = useState(false);
  const [showQ8, setShowQ8Page] = useState(false);
  const [showQ9, setShowQ9Page] = useState(false);
  const [showQ10, setShowQ10Page] = useState(false);
  const [plan, setPlanPage] = useState(false);
  const [result, setResultPage] = useState(false);

  const [q1ans, setQ1Ans] = useState([false, false, false, false, false, false, false, false]);
  const [userAns, setUserAns] = useState([-1, -1, -1, -1, 0, -1, -1, -1, -1, -1, -1]);

  const [quizResult, setQuizResult] = useState(0);

  const [appLanguage, setLanguage] = useState(0);




  function add(str, person){
    for(let ch of str){
      if (ch == "M"){
        person[0]++;
      }
      else if (ch == "W"){
        person[2]++;
      }
      else if (ch == "C"){
        person[1]++;
      }
      else if (ch == "B"){
        person[3]++;
      }
    }
  }


  function getRandomInt(max) {
    return Math.floor(Math.random() * max);
  }

  function calculateResult() {

    let person = [0, 0, 0, 0];

    const q1 = ["MW", "MW", "CB", "WC", "WB", "WC", "CB", "WB"];
    const q2 = ["CB", "MCB", "MW"];
    const q3 = ["CB", "MCB", "MW"];
    const q4 = ["C", "C", "C", "C", "B", "B", "B", "MW", "MW", "MW"];
    const q5 = ["B", "C", "W", "M"];
    const q6 = ["M", "W", "B", "C"];
    const q7 = ["M", "B", "W", "C"];
    const q8 = ["M", "B", "W", "C"];
    const q9 = ["B", "W", "C", "M"];
    const q10 = ["C", "B", "M", "W"];
    const ansBatch = [q2, q2, q2, q3, q4, q5, q6, q7, q8, q9, q10]


    console.log("calculate");
    for (let i = 0; i < q1ans.length; i++) {
      if (q1ans[i]){
        add(q1[i], person);
      }
    }
    for (let i = 2; i <= 10; i++) {
      add(ansBatch[i][userAns[i]], person);
    }

    for (let i = 0; i < 4; i++) {
      console.log(person[i]);
    }


    let largest = -1;
    for(let i=0; i<4; i++){
      let p = person[i];
      //console.log(p);
      if (p > largest){
        largest = p;
      }
    }
    
    
    let final = [];
    
    for(let i=0; i<4; i++){
      let p = person[i];
      if (p >= largest){
        final.push(i);
        //console.log(i);
      }
    }
    
    let finalIndex = final[getRandomInt(final.length)];
    setQuizResult(finalIndex);

    logPlay();
  }


  function logPlay() {
    // Create a Date object in GMT+8 (Singapore time zone)
    const currentDate = new Date().toLocaleString("en-US", { timeZone: "Asia/Singapore" });
  
    // Extract the date part (YYYY/MM/DD) and replace slashes with hyphens
    const dateOnly = currentDate.split(',')[0].replace(/\//g, '-');
  
    // Log the modified date
    console.log(dateOnly);

    const language = GetLanguage(); // Assuming you have a function to get the language
    const url = `https://findquiz-97f8c-default-rtdb.asia-southeast1.firebasedatabase.app/${dateOnly}/${language}.json`;
  
    getRequest(url);

    for (let i = 0; i < q1ans.length; i++) {
      if (q1ans[i]){
        const link = '1-' + i;
        const ansUrl = `https://findquiz-97f8c-default-rtdb.asia-southeast1.firebasedatabase.app/${dateOnly}/${link}.json`;
        getRequest(ansUrl);
      }
    }
    for (let i = 2; i <= 10; i++) {
      const link = i + '-' + userAns[i];
      const ansUrl = `https://findquiz-97f8c-default-rtdb.asia-southeast1.firebasedatabase.app/${dateOnly}/${link}.json`;
      getRequest(ansUrl);
      //add(ansBatch[i][userAns[i]], person);
    }

  }

  function getRequest(url){
    fetch(url)
    .then(response => response.json())
      .then(data => {
        // Handle the response data here\
        console.log(data);
        if (data === undefined || data === null) {
          console.log('Data is undefined or null.');
          sendPutRequest(url, 1);
        } else {
          // Handle the response data here
          const parsedData = parseInt(data, 10); // Assuming data is a string representing an integer
          if (!isNaN(parsedData)) {
            console.log('Parsed Data as Integer:', parsedData);
            sendPutRequest(url, parsedData+1);
          } else {
            console.log('Data is not a valid integer.');
          }
        }
      })
      .catch(error => {
        // Handle errors here
        console.error('Error:', error);
      });
  }

  function sendPutRequest(url, textData) {
    fetch(url, {
      method: 'PUT',
      body: textData,
    })
    .then(response => response.text())
      .then(responseData => {
        // Handle the response data here if needed
        console.log('PUT request successful:', responseData);
      })
      .catch(error => {
        // Handle errors here
        console.error('Error:', error);
      });
  }
  

  const updateQ1Ans = (index, value) => {
    // Create a copy of the userAns array
    const newUserAns = [...q1ans];
    // Update the value at the specified index
    newUserAns[index] = value;
    // Set the updated state using setUserAns
    setQ1Ans(newUserAns);    
  }
  

  const updateUserAns = (index, value) => {
    // Create a copy of the userAns array
    const newUserAns = [...userAns];
    // Update the value at the specified index
    newUserAns[index] = value;
    // Set the updated state using setUserAns
    setUserAns(newUserAns);
  };
  

  const pageSet = [setShowHome, setShowIntroPage, setShowQ1Page, setShowQ2Page, setShowQ3Page, setShowQ4Page,
  setShowQ5Page, setShowQ6Page, setShowQ7Page, setShowQ8Page, setShowQ9Page, setShowQ10Page,
  setPlanPage, setResultPage];

  const mainPage = () =>{
    pageSet[currentPageID](false);
    currentPageID = 0;
    pageSet[currentPageID](true);


    setQ1Ans([false, false, false, false, false, false, false, false]);
    setUserAns([-1, -1, -1, -1, 0, -1, -1, -1, -1, -1, -1]);
  }

  const nextPage = () => {
    pageSet[currentPageID](false);
    currentPageID++;
    pageSet[currentPageID](true);
  }

  const prevPage = () => {
    pageSet[currentPageID](false);
    currentPageID--;
    pageSet[currentPageID](true);
  }

  function GetLanguage(){
    if (appLanguage === 0){
        return "ENG";
    }
    else if (appLanguage === 1){
        return "KR";
    }
    else if (appLanguage === 2){
      return "JP";
    }
    else if (appLanguage === 3){
      return "ZH";
    }
    else if (appLanguage === 4){
      return "TH";
    }
    return 'ENG';
  }


  const q1Q = ['What are you interested in?', '어떤 운동에 관심이 있나요?',
'どんなスポーツに興味がありますか？', '你對什麼運動感興趣呢？', 'กิจกรรมใดที่คุณสนใจ?'];
  const q2Q = ['How would you describe your approach to fitness?', '어떤 스타일의 운동을 선호하나요?',
'フィットネスへの取り組み方を教えてください?', '你會如何描述你對運動的選擇呢？', 'คุณจะอธิบายแนวทางในการออกกำลังกายของคุณอย่างไร?'];
  const q3Q = ['What does your workout attire typically look like?', '선호하는 운동복 스타일은 어떤 것인가요?',
'あなたのトレーニングウエアはどんな色合いですか？', '你的運動裝通常是什麼風格的？', 'โดยปกติแล้วเสื้อผ้าออกกำลังกายของคุณมีลักษณะอย่างไร?'];
  const q4Q = ['Sustainability is important to me and my lifestyle', '지속가능성은 여러분과 여러분의 라이프스타일에 얼마나 중요한가요?',
'サステナビリティは、あなた自身やあなたのライフスタイルにとって重要ですか？', '永續發展對我和我的生活方式很重要。', 'ความยั่งยืนมีความสำคัญกับฉันและไลฟ์สไตล์ของฉัน'];
  const q5Q = ['How do you incorporate fashion into your active lifestyle?', '활동적인 일상을 위한 옷을 선택할 때 선호하는 스타일은 무엇인가요?',
'アクティブなライフスタイルにファッションをどのように取り入れていますか？', '你如何將時尚融入你生活方式中？', 'คุณผสานแฟชั่นไว้ในไลฟ์สไตล์แอ็คทีฟของคุณอย่างไร?'];
  const q6Q = ['What motivates you to stay active and healthy?', '활동적이고 건강한 삶을 위해 동기 부여가 되는 것은 무엇인가요?',
'活動的、健康的であり続けるためのモチベーションは何ですか？', '是什麼激勵著你保持活躍和健康？', 'อะไรเป็นแรงกระตุ้นให้คุณรักษาสุขภาพให้แข็งแรงและคงความกระฉับกระเฉง?'];
  const q7Q = ['How do you approach challenges and setbacks in fitness?', '운동을 하다가 어려움에 맞닥뜨릴 때 여러분은 어떻게 하나요?',
'フィットネスにおける課題や挫折をどのようにとらえますか？', '你如何應對運動時遇到的挑戰和挫折？', 'คุณรับมือกับความท้าทายและอุปสรรคในการออกกำลังกายอย่างไร?'];
  const q8Q = ['How do you define success in your fitness journey?', '운동을 하는 과정에 있어 성공을 어떻게 정의하고 있나요?',
'フィットネスにおける成功をどのように定義しますか？', '你如何定義在運動過程中的成功？', 'คุณนิยามความสำเร็จบนเส้นทางการออกกำลังกายของคุณไว้อย่างไร?'];
  const q9Q = ['How do you approach self-care and mindfulness outside of exercise?', '신체적 운동 외에 자기관리와 마음챙김을 위해 하는 것은 무엇인가요?',
'運動以外のセルフケアやマインドフルネスにどのように取り組んでいますか？', '除了運動之外，你如何實踐自我照顧和正念？', 'นอกเหนือจากการออกกำลังกายแล้ว คุณดูแลตัวเองและฝึกจิตใจอย่างไร?'];
  const q10Q = ['How do you view fitness as a part of your overall lifestyle?', '여러분의 라이프스타일에서 운동은 어디쯤 위치하나요?',
'あなたのライフスタイルの中でフィットネスをどのようにとらえていますか？', '你如何看待運動作為你整體生活方式的一部分？', 'คุณมองการออกกำลังกายเป็นส่วนหนึ่งของไลฟ์สไตล์โดยรวมของคุณอย่างไร?'];
  const q1extraQ = ['(Choose as many as you like)', '(중복 선택도 가능해요.)', '(複数選択可)', '(可選擇多項)', '(เลือกกี่ข้อก็ได้)']
  const q4extraQ = ['(Scale of Agree or Disagree)', '(동의 또는 비동의 여부 척도)', '(1-10でお答えください)', '(選擇數字越大同意程度越高，反之為不同意)', '(คะแนนความเห็นด้วยหรือไม่เห็นด้วย)']

  const q1AnsList = [["Yoga","Pilates", "Training", "Golf", "Running", "Hiking", "Tennis", "Swimming"],
  ["요가","필라테스", "트레이닝", "골프", "러닝", "하이킹", "테니스", "수영"],
  ["ヨガ","ピラティス", "ワークアウト", "ゴルフ", "ランニング", "ハイキング", "テニス", "水泳"],
  ["瑜伽","皮拉提斯", "訓練", "高爾夫", "跑步", "登山健行", "網球", "游泳"],
  ["โยคะ", "พิลาทิส", "เทรนนิง", "กอล์ฟ", "วิ่ง", "เดินป่า", "เทนนิส", "ว่ายน้ำ"]]

  const q2AnsList = [["I enjoy intense workouts that push my limits",
  "I prefer a balance of challenging exercise and mindful activities",
  "I prioritize low-impact exercises and activities"],

  ["한계를 뛰어넘는 강렬한 운동을 즐겨요",
  "도전적인 운동과 마인드풀한 활동의 균형을 선호해요",
  "저강도의 운동과 활동을 선호해요"],

  ["自分の限界に挑戦するような激しいワークアウトを楽しんでいる",
  "激しい運動とマインドフルなアクティビティの程よいバランスを好む",
  "負担の少ない運動やアクティビティを優先する"],

  ["我喜歡強度大、挑戰極限的運動",
  "我更喜歡兼顧有挑戰性的運動和身心正念活動",
  "我優先選擇低強度的運動和活動"],

  ["ฉันชอบการออกกำลังกายที่เข้มข้น ซึ่งท้าทายขีดจำกัดของฉัน",
"ฉันมองหาสมดุลระหว่างการออกกำลังกายที่ท้าทายกับกิจกรรมเพื่อฝึกจิตใจ",
"ฉันให้ความสำคัญกับการออกกำลังกายและกิจกรรมที่มีแรงกระแทกต่ำ"]]

  const q3AnsList = [["Bright and bold colors",
  "Vibrant and neutral colors",
  "Subtle and calming colors"],

  ["밝고 선명한 색상",
  "활기차면서도 뉴트럴한 색상",
  "은은하고 차분한 색상"],

  ["明るく大胆な色",
  "元気な中間色",
  "控えめで落ち着いた色"],

  ["鮮豔大膽的顏色",
  "明亮的中性顏色",
  "淡雅且平靜的顏色"],

  [
    "สีสดที่เด่นสะดุดตา",
    "สีพื้น ๆ ที่มีชีวิตชีวา",
    "สีอ่อนที่ให้ความรู้สึกสงบ"
  ]]

  const q5AnsList = [["I enjoy comfort",
  "I like to stand out",
  "I keep it simple",
  "I prioritize functionality"],

  ["편안함을 즐겨요",
  "눈에 띄는 것을 좋아해요",
  "심플한 것을 선호해요",
  "기능성을 최우선으로 생각해요"],

  ["快適さを楽しむ",
  "目立つことが好き",
  "シンプルにする",
  "機能性を優先"],

  ["我喜歡舒適",
  "我喜歡引人注目",
  "我保持簡約",
  "我優先考慮功能性"],

  [
    "ฉันชอบความสบาย",
    "ฉันชอบความโดดเด่น",
    "ฉันชอบความเรียบง่าย",
    "ฉันให้ความสำคัญกับฟังก์ชัน"
  ],]

  const q6AnsList = [["Reaching personal goals and challenging myself",
  "Enjoying the process of selfcare",
  "Having a balanced lifestyle",
  "Motivation of those around me"],

  ["개인적인 목표 달성 및 자기 자신에 대한 도전",
  "자기 관리 과정의 즐거움",
  "균형 잡힌 생활 방식을 가지는 것",
  "주변 사람들의 동기 부여"],

  ["個人的な目標を達成し、自分に挑戦すること",
  "セルフケアのプロセスを楽しむこと",
  "バランスのとれたライフスタイル",
  "周囲の人々のモチベーション"],

  ["實現個人目標並挑戰自我",
  "享受自我照顧的過程",
  "保持平衡的生活方式",
  "周圍人的激勵"],
  [
    "การบรรลุเป้าหมายส่วนตัวและการท้าทายตัวเอง",
    "ความชื่นชอบในขั้นตอนการดูแลตัวเอง",
    "การมีไลฟ์สไตล์ที่สมดุล",
    "แรงกระตุ้นจากคนรอบข้าง"
  ],]

  const q7AnsList = [["I see them as opportunities for growth and push through them",
  "I approach them with a positive mindset and find a solution",
  "I view them as opportunities for self-reflection",
  "I see them as a chance to learn"],

  ["성장의 기회로 여기고 극복해 나가요",
  "긍정적인 마음으로 접근하고 해결책을 찾아요",
  "자기 성찰의 기회로 봐요",
  "배움의 기회로 봐요"],

  ["成長の機会ととらえ、やり遂げようとする",
  "前向きに考え、解決策を見つける",
  "自分を見つめ直す機会だと思う",
  "学ぶチャンスととらえる"],

  ["我把它們視為成長的機會並克服它們",
  "我以積極的心態面對它們並找到解決方案",
  "我把它們視為自我反思的機會",
  "我把它們看作學習的機會"],
  [
    "ฉันมองสิ่งเหล่านี้เป็นโอกาสในการเติบโตและฝ่าฝันไปให้ได้",
    "ฉันรับมือกับสิ่งเหล่านี้ด้วยการคิดบวกและหาทางออก",
    "ฉันมองสิ่งเหล่านี้เป็นโอกาสในการทบทวนตัวเอง",
    "ฉันมองสิ่งเหล่านี้เป็นโอกาสในการเรียนรู้"
  ],]

  const q8AnsList = [["Achieving specific goals",
  "Finding joy and fulfilment",
  "Relationship between physical and mental wellbeing",
  "Enjoying time with workout companions"],

  ["구체적인 목표 달성",
  "기쁨과 성취감 찾기",
  "신체적 웰비잉과 정신적 웰비잉의 관계",
  "동료와 함께 운동하며 즐거운 시간을 보내는 것"],

  ["具体的な目標を達成すること",
  "喜びと充実感を得ること",
  "心と体のウェルビーイングをバランスを保つこと",
  "ワークアウト仲間との時間を楽しむこと"],

  ["達到具體的目標",
  "找到快樂和滿足",
  "身心健康之間的關係",
  "與健身伴侶一起享受時光"],
  [
    "บรรลุเป้าหมายที่ตั้งไว้",
    "พบกับความสุขและความอิ่มเอม",
    "ความสัมพันธ์ระหว่างสุขภาวะทางร่างกายและจิตใจ",
    "สนุกกับช่วงเวลาออกกำลังกายกับเพื่อน ๆ"
  ],]

  const q9AnsList = [["Activities that stimulate mind and body",
  "Enjoying the outdoors",
  "Spending time friends and family",
  "Rest and relaxation"],

  ["몸과 마음을 자극하는 활동",
  "야외 활동 즐기기",
  "가족 또는 친구와 함께 시간 보내기",
  "몸과 마음을 이완하며 휴식 취하기"],

  ["心身を刺激するアクティビティ",
  "アウトドアを楽しむ",
  "友人や家族と過ごす",
  "休息やリラックス"],

  ["進行能激發身心的活動",
  "享受戶外活動",
  "與朋友和家人共度時光",
  "休息和放鬆"],[
    "ทำกิจกรรมที่กระตุ้นจิตใจและร่างกาย",
    "เพลิดเพลินกับธรรมชาติ",
    "ใช้เวลากับเพื่อนฝูงและครอบครัว",
    "พักผ่อนและผ่อนคลาย"
  ],]

  const q10AnsList = [["It's central aspect of my life",
        "It's a top priority of my everyday routine",
        "It's incorporated to my routine on a weekly basis",
        "It's a component but not always a priority"],

  ["삶의 중심이에요",
  "하루 루틴에서 최우선 순위에 있어요",
  "매주 루틴에 포함돼요",
  "중요하지만, 항상 우선순위에 두진 않아요"],

  ["生活の中心である",
  "日常生活の最優先事項である",
  "週単位の日課に組み込んでいる",
  "一つの要素ではあるが、常に優先するものではない"],

  ["它是我生活的中心",
  "它是我每天日常的首要事項",
  "它是我每週例行活動的一部分",
  "它是一個組成部分，但不總是優先考慮的事情"],

  [
    "การออกกำลังกายเป็นส่วนสำคัญของชีวิต",
    "การออกกำลังกายเป็นสิ่งสำคัญอันดับต้น ๆ ในกิจวัตรประจำวัน",
    "การออกกำลังกายเป็นส่วนหนึ่งของกิจวัตรประจำสัปดาห์",
    "การออกกำลังกายเป็นองค์ประกอบหนึ่ง แต่ไม่ใช่สิ่งสำคัญเสมอไป"
  ]
]

  


  return (
    <div className="App">

<CSSTransition
        in={showHome}
        timeout={500}
        classNames="fade"
        unmountOnExit
      >
        <StartPage setLanguage={setLanguage}  toIntroPage={nextPage}/>
</CSSTransition>


<CSSTransition
        in={showIntroPage}
        timeout={500}
        classNames="fade"
        unmountOnExit
      >
        <IntroPage language={GetLanguage()} toHomePage={nextPage} />
</CSSTransition>


<CSSTransition
        in={showQ1}
        timeout={500}
        classNames="fade"
        unmountOnExit
      >
<QuestionPageMultiple questionNo={1}
    setAns={updateQ1Ans}
    language={GetLanguage()}
    selectedAns={q1ans}
    question={q1Q[appLanguage]}
    extraInstruct={q1extraQ[appLanguage]}
    ans={q1AnsList[appLanguage]} 
    nextPage={nextPage} prevPage ={prevPage}/>
</CSSTransition>

<CSSTransition
        in={showQ2}
        timeout={500}
        classNames="fade"
        unmountOnExit
      >
<QuestionPage questionNo={2}
    setAns={updateUserAns}
    language={GetLanguage()}
    selectedAns={userAns[2]}
    question={q2Q[appLanguage]}
    ans={q2AnsList[appLanguage]} 
    nextPage={nextPage} prevPage ={prevPage}/>
</CSSTransition>

<CSSTransition
        in={showQ3}
        timeout={500}
        classNames="fade"
        unmountOnExit
      >
<QuestionPage questionNo={3} 
    setAns={updateUserAns}
    language={GetLanguage()}
    selectedAns={userAns[3]}
  question={q3Q[appLanguage]}
    ans={q3AnsList[appLanguage]} 
    nextPage={nextPage} prevPage ={prevPage}/>
</CSSTransition>




<CSSTransition
        in={showQ4}
        timeout={500}
        classNames="fade"
        unmountOnExit
      >
<QuestionPageBar questionNo={4} 
    setAns={updateUserAns}
    language={GetLanguage()}
    selectedAns={userAns[4]}
  question={q4Q[appLanguage]}
  extraInstruct={q4extraQ[appLanguage]}
    ans={["1", "2", "3", "4" ,"5" ,"6" ,"7" ,"8" ,"9" ,"10"]} 
    nextPage={nextPage} prevPage ={prevPage}/>
</CSSTransition>



<CSSTransition
        in={showQ5}
        timeout={500}
        classNames="fade"
        unmountOnExit
      >
<QuestionPage questionNo={5} 
    setAns={updateUserAns}
    language={GetLanguage()}
    selectedAns={userAns[5]}
  question={q5Q[appLanguage]}
    ans={q5AnsList[appLanguage]} 
    nextPage={nextPage} prevPage ={prevPage}/>
</CSSTransition>




<CSSTransition
        in={showQ6}
        timeout={500}
        classNames="fade"
        unmountOnExit
      >
<QuestionPage questionNo={6} 
    setAns={updateUserAns}
    language={GetLanguage()}
    selectedAns={userAns[6]}
  question={q6Q[appLanguage]}
    ans={q6AnsList[appLanguage]} 
    nextPage={nextPage} prevPage ={prevPage}/>
</CSSTransition>



<CSSTransition
        in={showQ7}
        timeout={500}
        classNames="fade"
        unmountOnExit
      >
<QuestionPage questionNo={7} 
    setAns={updateUserAns}
    language={GetLanguage()}
    selectedAns={userAns[7]}
  question={q7Q[appLanguage]}
    ans={q7AnsList[appLanguage]} 
    nextPage={nextPage} prevPage ={prevPage}/>
</CSSTransition>



<CSSTransition
        in={showQ8}
        timeout={500}
        classNames="fade"
        unmountOnExit
      >
<QuestionPage questionNo={8} 
language={GetLanguage()}
    setAns={updateUserAns}
    selectedAns={userAns[8]}
  question={q8Q[appLanguage]}
    ans={q8AnsList[appLanguage]} 
    nextPage={nextPage} prevPage ={prevPage}/>
</CSSTransition>



<CSSTransition
        in={showQ9}
        timeout={500}
        classNames="fade"
        unmountOnExit
      >
<QuestionPage questionNo={9} 
language={GetLanguage()}
    setAns={updateUserAns}
    selectedAns={userAns[9]}
  question={q9Q[appLanguage]}
    ans={q9AnsList[appLanguage]} 
    nextPage={nextPage} prevPage ={prevPage}/>
</CSSTransition>



<CSSTransition
        in={showQ10}
        timeout={500}
        classNames="fade"
        unmountOnExit
      >
<QuestionPage questionNo={10} 
language={GetLanguage()}
    calculate={calculateResult}
    setAns={updateUserAns}
    selectedAns={userAns[10]}
  question={q10Q[appLanguage]}
    ans={q10AnsList[appLanguage]} 
    nextPage={nextPage} prevPage ={prevPage}/>
</CSSTransition>



<CSSTransition
        in={plan}
        timeout={500}
        classNames="fade"
        unmountOnExit
      >
<PlanPage result={quizResult} language={GetLanguage()}  next={nextPage} prev ={prevPage}/>
</CSSTransition>


<CSSTransition
        in={result}
        timeout={500}
        classNames="fade"
        unmountOnExit
      >
<ResultPage result={quizResult} language={GetLanguage()}  next={mainPage} prev ={prevPage}/>
</CSSTransition>



    </div>
  );
}

export default App;
