import React from 'react';
import { useMediaQuery } from 'react-responsive';
import CustomText from './CustomText';
import CustomButton from './CustomButton';
import MyText from './MyText';
import Div100vh, { use100vh } from 'react-div-100vh';
import QRCode from 'react-qr-code';

const ResultPage = ({ language, result, next, prev }) => {
  const isLandscape = useMediaQuery({ query: '(orientation: landscape)' });

  function GetIMG(){
  // Ensure that the result is a number
  const resultNumber = parseInt(result) + 1;

  if (isNaN(resultNumber) || resultNumber < 0) {
    // Handle invalid or negative results here
    return "";
  }

  const lang = language.toString().toLowerCase();

  // Assuming your image files are named as "1.png", "2.png", and so on...
  return `/result/${lang}/${resultNumber}.webp`;
  }

  const divStyle = {

    width: '100%',
    height: '100%',
    position: 'absolute'
    
};

  const backgroundImageStyle = {

    width: '100%',
    height: '100%',

  };

  function GetPad() {
    return isLandscape ? '7%' : '10%';
  }

  const findStyle = {
    height: isLandscape ? '8vh' : '3.8vh',
    position: 'absolute',
    top: isLandscape ? GetPad() : '5%',
    right: GetPad(),
  };

  const centerDivStyle = {
    width: '86%',
    height: '86%',

    position: 'absolute',
    top: GetPad(),
    left: GetPad(),
    display: 'flex',
    justifyContent: 'space-between', // Space between columns
    alignItems: 'center', // Vertically center the content
  };

  const centerPortraitDivStyle = {
    width: '80%',
    height: '70%',
    position: 'absolute',
    top: '12%',
    left: GetPad(),
  };


  const columnStyle = {

    flex: '1.4', // Equal width for both columns
    height: '100%',
  };

  const column2Style = {

    flex: '1', // Equal width for both columns
    height: '100%',
    display: 'flex', // Enable flexbox for vertical centering
    flexDirection: 'column', // Stack content vertically
    justifyContent: 'center', // Vertically center the content
    alignItems: 'flex-start', // Align content to the left

  };

  function GetButTextSize() {
    return isLandscape ? '2.5vw' : '2.5vh';
  }

  function save(){

    const link = document.createElement('a');
    link.href = GetIMG();
    link.download = 'result.webp'; // Specify the file name here
    link.click();
    link.remove();

  }

  function GetPrev(){
    if (language == "ENG"){
      return "PREV";
    }
    else if (language == "JP"){
      return "戻る";
    }
    else if (language == "KR"){
      return "이전";
    }
    else if (language == "ZH"){
      return "上一頁";
    }
    else if (language == "TH"){
      return "ก่อนหน้า";
    }
    return "PREV";
  }

  function GetMenu(){
    if (language == "ENG"){
      return "RETURN TO MENU";
    }
    else if (language == "JP"){
      return "メニューに戻る";
    }
    else if (language == "KR"){
      return "메뉴로 돌아가기";
    }
    else if (language == "ZH"){
      return "返回首頁";
    }
    else if (language == "TH"){
      return "กลับไปที่เมนู";
    }
    return "PREV";
  }

  const qr1={
    ENG: "Scan to save and share your",
    JP: "保存して、あなたのお友",
    KR: "스캔하여 결과를 저장하고",
    ZH: "掃描儲存並分享測驗結果",      
    TH: "สแกนเพื่อบันทึกและแชร์ผล",
  }
  const qr2={
    ENG: "results with your friends",
    JP: "達にもシェアしてね",
    KR: "친구들과 공유하세요",
    ZH: "", 
    TH: "การทดสอบของคุณกับเพื่อน",     
  }
  const saveText={
    ENG: "Save",
    JP: "保存する",
    KR: "저장",
    ZH: "儲存", 
    TH: "บันทึก",
  }

  const viewsave1={
    ENG: "Save and share your results",
    JP: "保存して、あなたのお",
    KR: "결과를 저장하고",
    ZH: "儲存並與朋友分享",      
    TH: "บันทึกและแชร์ผลการทดสอบ",
  }

  const viewsave2={
    ENG: "with your friends",
    JP: "友達にもシェアしてね",
    KR: "친구들과 공유해 보세요",
    ZH: "你的身心測驗結果",    
    TH: "ของคุณกับเพื่อน",  
  }



  return (



    <div style={divStyle}>
      <img src={isLandscape ? '/questionbg/q4-landscape.webp' : '/questionbg/q4-portrait.webp'} alt="bg" style={backgroundImageStyle} />
      <img src={`/logo/smallfind${language.toLowerCase()}.webp`} alt="Find SVG" className="find-svg" style={findStyle} />

      {!isLandscape && (
        <div style={ centerPortraitDivStyle }>
          {/* Add your content for the centered div here */}
          <img
            src={GetIMG()}
            alt="Image"
            style={{
              height: '80%',
              width: 'auto',
              border: '1px solid white', // Add a white border with 1px width
              boxSizing: 'border-box', // Ensure that the border width is included in the image dimensions
            }}
          />          
          <div style={{ height: isLandscape ? '1vh' : '1vh' }}></div>
          <MyText font={language} fontSize="2vh" text={viewsave1[language]} />
          <br/>
          <MyText font={language} fontSize="2vh" text={viewsave2[language]} />
          <div style={{ height: isLandscape ? '1vh' : '1vh' }}></div>
          <CustomButton onClick={save} icon='/download.svg' fontSize='2vh' font="ENG" padding='0.5vh 3vh 0.5vh 3vh' position='static' >{saveText[language]}</CustomButton>
        </div>
      )}


      {isLandscape && <div style={centerDivStyle}>
        {/* Column 1 */}
        <div style={columnStyle}>
          {/* Add your content for the first column here */}
          <img
            src={GetIMG()}
            alt="Image"
            style={{
              height: '100%',
              width: 'auto',
              border: '1px solid white', // Add a white border with 1px width
              boxSizing: 'border-box', // Ensure that the border width is included in the image dimensions
            }}
          />
        </div>

        {/* Column 2 */}
        <div style={column2Style}>
          {/* Add your content for the second column here */}
          <MyText font={language} fontSize={isLandscape ? '2.5vw' : '1.5vh'} text={qr1[language]} />
          <MyText font={language} fontSize={isLandscape ? '2.5vw' : '1.5vh'} text={qr2[language]} />


            <QRCode  
              bgColor='transparent'
              fgColor='white'
              style={{ height: "15%", width: "auto" }}
              value={`discover.lululemonfindyourwellbeing.com/result/${language.toLowerCase()}/${(result+1)}.webp`}
              >    
            </QRCode>
        </div>
      </div>}



      {!isLandscape && <CustomText onClick={prev}   font={language} text={GetPrev()} fontSize={isLandscape ? '2vw' : '2vh'} left={GetPad()} top={isLandscape? '90%' : '90%'}/>} 
 

      <CustomText
        onClick={next}
        textAlign="right"
        font={language}
        text={GetMenu()}
        fontSize={isLandscape ? '2vw' : '2vh'}
        right={GetPad()}
        top={isLandscape? '90%' : '90%'}
      />
    </div>


  );
};

export default ResultPage;
