import React from 'react';
import './fonts.css';

const CustomText = ({lineHeight = 'normal', bottom, width='auto',  position = 'absolute', transform = 'none', font, text = '', fontSize, top, left, right, maxWidth, fontWeight = 'normal', onClick }) => {
  const style = {
    fontWeight: fontWeight,
    fontFamily: font,
    fontSize: fontSize || '4vw',
    color: 'white',
    textAlign: 'left',
    position: position,
    top: top,
    bottom:bottom,
    left: left,
    right: right,
    width :width,
    maxWidth: maxWidth || 'none',
    userSelect: 'none',
    //backgroundColor: 'black',
    lineHeight : lineHeight,
    transform: transform,
    wordBreak: font === "KR" ? 'keep-all' : "normal",
  };

  // Replace '\n' with <br /> tags
  const formattedText = text.split('\n').map((line, index) => (
    <React.Fragment key={index}>
      {line}
      {index !== text.length - 1 && <br />}
    </React.Fragment>
  ));

  return (
    <div style={style} onClick={onClick}>
      {formattedText}
    </div>
  );
};

export default CustomText;
