import React from 'react';

const GridContainer = ({position = 'absolute', border = 'none', borderRadius = '0px',  display = 'grid', children, gapX = '0px', gapY = '10px', width = 'auto', height = 'auto', right = 'auto', left = 'auto', top = 'auto', columns = 'auto', rows = 'auto' }) => {
  const gridContainerStyle = {
    border:border,
    borderRadius:borderRadius,
    display: display,
    textAlign: 'left',
    gridTemplateColumns: columns,
    gridTemplateRows: rows, // Add support for dynamic rows
    gap: `${gapY} ${gapX}`,
    height: height,
    width: width,
    //backgroundColor: '#2196F3',
    position: position,
    top: top,
    left: left,
    right: right,

  };

  return (
    <div className="grid-container" style={gridContainerStyle}>
      {children}
    </div>
  );
};

export default GridContainer;
