import React from 'react';
import { useMediaQuery } from 'react-responsive';
import CustomText from './CustomText';
import CustomButton from './CustomButton';
import Div100vh, { use100vh } from 'react-div-100vh';


const IntroPage = ({ language, toHomePage }) => {


    
    const isLandscape = useMediaQuery({ query: '(orientation: landscape)' });

    const divStyle = {

      width: '100%',
      height: '100%',
      position: 'absolute'
      
  };
  
    const backgroundImageStyle = {

      width: '100%',
      height: '100%',

    };
  
    function GetPad(){
      return isLandscape ? '7%' : '10%'
    }
  
    const svgStyle = {
      //width: isLandscape ? 'auto' : '30vw', // Set the SVG width conditionally
      height: isLandscape ? '5vh' : '3vh',
      position: 'absolute', // To position the SVG within the container
      top: GetPad(), // Vertically center the SVG
      left: GetPad(), // Horizontally center the SVG
      //padding: GetPad(),
    };
  
    const findStyle = {

      height: isLandscape ? '15vh' : '8vh',
      position: 'absolute', // To position the Find SVG within the container
      top: isLandscape ? '25%' : '30%', // Vertically center the Find SVG
      left: '0', // Place the Find SVG 10% from the left
      transform: 'translateY(-50%)', // Center the Find SVG vertically
      padding: GetPad(),
    };
  
    const handleClick = () => {
        toHomePage();
    };
  
    function GetButTextSize(){
      return isLandscape ? '3vh' : '2vh'
    }

    function GetText(){
      if (language === "ENG"){
          return "We believe a holistic approach to wellbeing involves nurturing your mind, body, and community. We encourage the practice of being fully present, fostering deep connections with yourself and the people around you.\n\nThis short quiz is created to support you on your journey of holistic wellbeing. Answer a few simple questions to understand your wellbeing profile. Once complete, you will receive a 7-day plan suited to your profile. Use this plan as a guide to kickstart your commitment to mindfulness, connection and physical wellbeing no matter where you are.";
      }
      else if (language === "KR"){
          return "웰비잉을 위한 접근에는 몸과 정신 그리고 커뮤니티를 건강하게 하는 모든 것이 포함된다고 생각해요. \n우리는 여러분이 현재에 충실하고, 자신과 주변 사람들과 깊은 관계를 맺을 수 있길 응원해요. \n\n이 짧은 퀴즈는 나만의 특별한 웰비잉 여정을 돕기 위해 만들어졌어요. \n여러분을 이해하기 위한 몇 가지 간단한 질문에 답해주세요. 퀴즈를 완료하면 나만의 7-day 웰비잉 플랜을 받을 수 있어요. \n이 플랜과 함께라면 여러분이 어디에 있든 마음챙김과 커넥션, 그리고 신체적 웰비잉을 위해 노력할 수 있을 거예요.";
      }
      else if (language === "JP"){
        return "ウェルビーイングの実現に向けた総体的な取り組みには、心と体、そしてコミュニティを育むことが欠かせないと私たちは信じています。自分自身や周りの人々と深いつながりを築きながら、今のこの瞬間を感じるプラクティスをしましょう。\n\nこの簡単なクイズは、あなたのウェルビーイング探しをサポートするために作られました。簡単な質問に答えるだけで、あなたのウェルビーイングの状態がわかります。全ての質問に答えると、あなたに合った7日間のプランが完成します。このプランをガイドとして活用し、どこにいようとも、マインドフルネス、人とのつながり、そして身体的な健康への第一歩を踏み出しましょう。";
      }
      else if (language === "ZH"){
        return "我們相信全面的身心幸福包含擁有健康的身體、心靈和社群。我們鼓勵你全心投入當下的生活方式，並與自己和周遭的人建立深厚的連結。\n\n這個簡短的小測試旨在支持你全面的身心幸福。回答幾個簡單的問題，了解你的身心概況。完成後，你將收到一份適合你的七日計劃。無論你身在何處，都可以使用這份計劃作為啟動你對正念、連結和身心健康的承諾的指南。";
      }
      else if (language == "TH"){
        return "เราเชื่อว่าแนวทางที่ส่งเสริมการมีสุขภาวะที่ดีแบบองค์รวมต้องอาศัยการดูแลเอาใจใส่ร่างกาย จิตใจ และชุมชน เราส่งเสริมการฝึกฝนการอยู่กับปัจจุบัน และสนับสนุนความสัมพันธ์อันลึกซึ้งกับตัวเองและคนรอบข้าง\n\nแบบทดสอบสั้น ๆ นี้จัดทำขึ้นเพื่อสนับสนุนคุณบนเส้นทางสู่การมีสุขภาวะที่ดีแบบองค์รวม เพียงตอบคำถามง่าย ๆ ไม่กี่ข้อเพื่อทำความเข้าใจโปรไฟล์ด้านสุขภาวะของคุณ เมื่อทำแบบทดสอบแล้ว คุณจะได้รับแผนสำหรับ 7 วันที่เหมาะสำหรับโปรไฟล์ของคุณ ใช้แผนนี้เป็นแนวทางเพื่อเริ่มต้นเส้นทางสู่การมีสติ การเชื่อมสัมพันธ์ และการมีสุขภาวะที่ดีทางร่างกายไม่ว่าคุณจะอยู่ที่จุดใดก็ตาม";
      }
      return '';
    }

    function GetButText(){
      if (language === "ENG"){
          return "Let's begin";
      }
      else if (language === "KR"){
          return "시작";
      }
      else if (language === "JP"){
        return "さあ、始めましょう";
      }
      else if (language === "ZH"){
        return "開始吧";
      }
      else if (language === "TH"){
        return "เริ่มกันเลย";
      }
      return '';
    }


  
    return (


      <div style={divStyle}>
        
        {/* You can add content inside this div if needed */}
        <img src={isLandscape ? '/bg2-landscape.webp' : '/bg2-portrait.webp'} alt="bg" style={backgroundImageStyle} />
        <img src="/lulu.svg" alt="Lulu SVG" className="lulu-svg" style={svgStyle} />


        <img src={`/logo/find${language.toLowerCase()}.webp`} alt="Find SVG" className="find-svg" style={findStyle} />
        <CustomText lineHeight='1.1' font={language} fontSize={isLandscape ? '3.3vh' : '2.1vh'} left={GetPad()} top="35%" maxWidth="75vw" text={GetText()} />
    <CustomButton font={language} padding='1vh 4vh 1vh 4vh' position='absolute' bottom="8%" right="7%"  fontSize={GetButTextSize()} onClick={handleClick} >{GetButText()}</CustomButton>

  
  
  
      </div>



    );
  };
  
  export default IntroPage;