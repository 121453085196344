import React from 'react';

const NormalContainer = ({display = 'inline', children, width = 'auto', height = 'auto', right = 'auto', left = 'auto', top = 'auto'}) => {
  const gridContainerStyle = {


    display: display,

    textAlign:'left',
    height: height,
    width: width,
    //backgroundColor: '#2196F3',
    position: 'absolute',
    top: top,
    left: left,
    right: right,
  };

  return (
    <div style={gridContainerStyle}>
      {children}
    </div>
  );
};

export default NormalContainer;
