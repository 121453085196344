import React from 'react';
import './fonts.css';

const MyText = ({width='auto', height='auto', paddingLeft = '0px', font, text = '', fontSize, fontWeight = 'normal'}) => {
  const style = {
    width:width,
    height:height,
    fontWeight: fontWeight,
    fontFamily: font,
    fontSize: fontSize || '4vw',
    color: 'white',
    textAlign: 'left',
    paddingLeft:paddingLeft,
    userSelect: 'none',
    wordBreak: font === "KR" ? 'keep-all' : "normal",
  };

  // Replace '\n' with <br /> tags


  return (
    <span style={style}>
      {text}
    </span>
  );
};

export default MyText;
