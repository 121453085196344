import React from 'react';
import './CustomButton.css'; // You'll create this CSS file for styling
import './fonts.css';

const CustomButton = ({
  isBorder = true,
  isActive,
  padding = '0px',
  margin = '0px',
  textAlign = 'center',
  children,
  onClick,
  font,
  fontSize,
  width = 'auto',
  height = 'auto',
  position = 'static',
  top,
  bottom,
  left,
  right,
  lineHeight = 'normal',
  icon
}) => {
  const buttonStyle = {
    border: isBorder ? '1px solid white' : '1px solid transparent',
    backgroundColor: isActive ? 'white' : 'transparent',
    color: isActive ? 'black' : 'white',
    textAlign: textAlign,
    fontFamily: font, // Apply the font style to the button
    fontSize: fontSize,
    width: width,
    height: height,
    position: position,
    top: top,
    bottom: bottom,
    left: left,
    right: right,
    lineHeight: lineHeight,
    padding: padding,
    margin: margin,
    userSelect: 'none',
    wordBreak: font === "KR" ? 'keep-all' : "normal",
  };

  const textDivStyle = {
    wordBreak: font === "KR" ? 'keep-all' : "normal",
    display: 'inline-block',
    paddingTop: (font === 'ENG' || font === "TH") ? '0.7vh' : '0', // Apply the yOffset to control the vertical position of the span
  };

  const imageDivStyle = {
    width: icon ? fontSize : '0px',
    paddingLeft: icon ? '1vh' : '0px',
    display: 'inline-block',
  };

  return (
    <button className="custom-button" style={buttonStyle} onClick={onClick}>
      <div style={textDivStyle}>{children}</div>
      <div style={imageDivStyle}>
        {icon&&<img src={icon} alt='Download' />}
      </div>
    </button>
  );
};

export default CustomButton;
