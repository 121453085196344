import React from 'react';
import { useMediaQuery } from 'react-responsive';
import CustomText from './CustomText';
import CustomButton from './CustomButton';
import NormalContainer from './NormalContainer';
import MyText from './MyText';
import TextWithLine from './Line';
import Div100vh, { use100vh } from 'react-div-100vh';
import QRCode from 'react-qr-code';


const PlanPage = ({language, result, next, prev }) => {


    
    const isLandscape = useMediaQuery({ query: '(orientation: landscape)' });

    const divStyle = {

      width: '100%',
      height: '100%',
      position: 'absolute'
      
  };
  
    const backgroundImageStyle = {

      width: '100%',
      height: '100%',

    };
  
    function GetPad(){
      return isLandscape ? '7%' : '10%'
    }
  
    const findStyle = {
        //width: isLandscape ? 'auto' : '45vw', // Set the SVG width conditionally
        height: isLandscape ? '8vh' : '3.8vh',
        position: 'absolute', // To position the Find SVG within the container
        top: isLandscape ? '12%' : '7%', // Vertically center the Find SVG
        right: '0%', // Place the Find SVG 10% from the left
        transform: 'translateY(-50%)', // Center the Find SVG vertically
        padding: GetPad(),
      };


  
    const handleClick = () => {
        next();
    };
  
    function GetButTextSize(){
      return isLandscape ? '2.5vw' : '2.5vh'
    }

    const centerDivStyle = {

      height: '100px',
      backgroundColor: 'green',

      top: '80%',
      left: GetPad(),
      textAlign: 'left',
    };

    const qrStyle = {
      height:'100px'
    }

    const thoughtful = {
      ENG: "THOUGHTFUL EXPLORER",
      JP: "思いやりのある探検家",
      KR: "사려 깊은 탐험가",
      ZH: "深思熟慮的探索者",
      TH: "นักสำรวจผู้ไตร่ตรอง",
    };

    const connector = {
      ENG: "ENGAGED CONNECTOR",
      JP: "つながりを築くコネクター",
      KR: "적극적인 중계자",
      ZH: "積極的社交者",
      TH: "นักเชื่อมสัมพันธ์ผู้กระตือรือร้น",
    };

    const harmon = {
      ENG: "FLOURISHING HARMONIZER",
      JP: "華麗なるハーモナイザー",
      KR: "활기찬 화합가",
      ZH: "繁榮的和諧者",
      TH: "นักผสมผสานที่เฟื่องฟู",
    };

    const advocate = {
      ENG: "HOLISTIC ADVOCATE",
      JP: "ホリスティックな提唱者",
      KR: "전인적인 지지자",
      ZH: "全面的提倡者",
      TH: "ผู้สนับสนุนความบริบูรณ์",
    };

    function GetRole(){
      let role = '';
      if (result == 0){
        role = thoughtful[language]
      }
      else if (result == 1){
        role = connector[language]
      }
      else if (result == 2){
        role = harmon[language]
      }
      else if (result == 3){
        role = advocate[language]
      }
      return role;
    }

    const text1 = {
      ENG: "Embodying a sense of present-moment awareness and non-judgmental attentiveness. You possess a natural inclination towards cultivating mindfulness in your daily life.",
      JP: "あなたは、そこに起きていることへの気づきと、偏見のない気配りを体現している人です。日常生活の中でマインドフルネスを養うことに天性の素質を持っています。",
      KR: "현재 순간을 인지하는 능력과 주변에 대한 편견없는 관심을 가지고 있어요. 일상생활에서자연스럽게 마음챙김을 위한 행동을 하고 있어요.",
      ZH: "你具有當下的覺知和非評判性的專注力，內在自然傾向於在日常生活中培養正念。",
      TH: "คุณคือตัวแทนของการตระหนักรู้ในปัจจุบันและการเอาใจใส่โดยไม่ตัดสิน คุณมีแนวโน้มโดยธรรมชาติที่จะดำเนินชีวิตประจำวันอย่างมีสติ",
    };

    const text2 = {
      ENG: "You are defined by your innate ability to build bridges and cultivate a sense of community among those around you. You excel in social settings and actively foster your connections.",
      JP: "あなたは、人々の心をつなぎ、周囲の人たちの間にコミュニティの感覚を培う天賦の才能を持ち合わせています。社交的な場を得意とし、積極的に人脈を育みます。",
      KR: "주변 사람들과 소통하고 사람들을 연결하며 공동체 의식을 키우는데 타고난 능력이있어요. 여러분은 사회적 관계 형성에 탁월하며 적극적으로 관계를 맺어요.",
      ZH: "你與生俱來便具有為周遭人之間搭建溝通橋樑和培養社區意識的能力。你在社交環境中表現出色，積極促進你的人際關係。",
      TH: "สิ่งที่นิยามคุณคือความสามารถโดยกำเนิดในการเชื่อมสัมพันธ์และบ่มเพาะความรู้สึกแห่งชุมชนในหมู่ผู้คนรอบตัวคุณ",
    };

    const text3 = {
      ENG: "Epitomised by a dedication to pursue a fulfilling life. You seek to find balance and wellbeing of your physical, mental, and emotional dimensions, while prioritising harmony and vitality.",
      JP: "充実した人生を求めるひたむきさの象徴。あなたは、調和と活力を優先しながら、肉体的、精神的、感情的な側面のバランスと健康を追求する人です。",
      KR: "성취감 있는 삶을 위해 적극적으로 노력하는 유형이에요. 여러분은 화합과 활력있는 삶을우선시하면서 신체적, 정신적 그리고 감정적 차원의 균형과 웰비잉을 찾으려고 노력해요.",
      ZH: "你以追求充實的生活為特徵。尋求在身體、心理和情感層面找到平衡和健康，同時優先考慮和諧和活力。",
      TH: "คุณคือตัวแทนของการอุทิศตนเพื่อการมีชีวิตที่สุขสมบูรณ์ คุณต้องการพบกับความสมดุลและสุขภาวะที่ดีทั้งทางร่างกาย จิตใจ และอารมณ์ แต่ก็ให้ความสำคัญกับความกลมกลืนและความมีชีวิตชีวา",
    };

    const text4 = {
      ENG: "Remember that spiritual nourishment is valuable and your wellbeing journey can include committed practices that promote health and clarity to the body and mind.",
      JP: "あなたはバランスのとれた状態への憧れ、内なる平和と調和の感覚を培いたいという深い願望を持っています。あなたの旅は、安定を育むライフスタイルが中心となるでしょう。",
      KR: "균형 있는 삶에 대한 열망 그리고 내면의 평화와 조화를 키우려는 열렬한 소망을 가지고있군요. 여러분의 여정은 안정감과 온전함을 키우는 라이프스타일이 중심이 될 거예요.",
      ZH: "你天生渴望平衡，深刻渴望培養內在的平靜與和諧感，你的旅程圍繞著一種促進穩定和完整的生活方式。",
      TH: "โดยเนื้อแท้แล้วคุณปรารถนาสมดุลและมีความต้องการอันลึกซึ้งที่จะบ่มเพาะความสงบและความกลมกลืนภายใน คุณให้ความสำคัญกับไลฟ์สไตล์ที่ส่งเสริมความมั่นคงและความบริบูรณ์",
    };


    const text5 = {
      ENG: "Experience each moment with calmness and a profound connection to the present. Your commitment to mindfulness infuses physical activities with intention, unwavering focus, and a serene sense of peace.",
      JP: "一瞬一瞬を穏やかに、今、この瞬間とのつながりを感じ取ってください。マインドフルネスに取り組むことで、身体的な活動に意志が吹き込まれ、揺るぎない集中力と穏やかな平和の感覚が生まれます。",
      KR: "매 순간 평온함과 현재와의 깊은 연결을 느껴보세요. 마음챙김을 향한 여러분의 노력은신체활동에도 목적과 확고한 집중력, 그리고 고요한 평화를 불러일으킬 거에요.",
      ZH: "以冷靜和對當下的深刻連接體驗每一刻。你對正念的承諾賦予了身體活動意圖、堅定的專注和寧靜的平和感。",
      TH: "คุณใช้เวลาแต่ละชั่วขณะด้วยความสงบและเชื่อมต่อกับปัจจุบันในระดับที่ลึกล้ำ คุณยึดมั่นในการมีสติ และผสานจุดมุ่งหมาย โฟกัสที่แน่วแน่ และความรู้สึกสงบนิ่งไว้ในกิจกรรมเคลื่อนไหวร่างกาย",
    };

    const text6 = {
      ENG: "Fearlessly take on new challenges and let your determination serve as a source of inspiration for others, to help you reach new heights and achieve your wellbeing goals.",
      JP: "あなたは、新しいことに大胆に挑戦し、その決意を他の人たちのインスピレーションの源とすることで、新たな高みに到達し、ウェルビーイングの目標を達成することができるでしょう。",
      KR: "두려움 없이 늘 새로운 도전을 하고, 다른 사람들에게 영감을 주세요. 이는 여러분을새로운 정점에 도달하게 하고, 웰비잉 목표를 달성하게끔 도와줄 거예요.",
      ZH: "勇敢地迎接新挑戰，讓你的決心成為他人的靈感來源，幫助你達到新的高度，實現你的健康目標。",
      TH: "คุณพร้อมรับความท้าทายใหม่ ๆ โดยไม่เกรงกลัว และใช้ความมุ่งมั่นของคุณเพื่อสร้างแรงบันดาลใจให้กับผู้อื่น ซึ่งช่วยให้คุณก้าวไปอีกขั้นและบรรลุเป้าหมายด้านสุขภาวะที่ดี",
    };

    const text7 = {
      ENG: "Remember that spiritual nourishment is valuable and your wellbeing journey can include committed practices that promote health and clarity to the body and mind.",
      JP: "スピリチュアルな栄養の大切さを忘れないでください。ウェルビーイングの旅には、心身の健康と明晰さを高めるための習慣が欠かせません。",
      KR: "정신적 자양분이 얼마나 소중한지와 여러분의 웰비잉 여정에는 몸과 마음의 건강함과명료함을 증진하는 노력의 실천이 필요하단 걸 기억하세요.",
      ZH: "請記住，精神的滋養是寶貴的，你的健康之旅可以包括致力於促進身心健康和清晰度的實踐。",
      TH: "อย่าลืมว่าการบำรุงจิตวิญญาณก็เป็นสิ่งสำคัญ และเส้นทางสู่การมีสุขภาวะที่ดีของคุณอาจรวมถึงการปฏิบัติตนด้วยความมุ่งมั่นเพื่อส่งเสริมสุขภาพและความสดใสทางร่างกายและจิตใจ",
    };

    const text8 = {
      ENG: "To support the pursuit of a holistic wellbeing, recognise the connection between your physical, mental, and social aspects of life and nourish all faucets to find a sense of equilibrium.",
      JP: "全体的なウェルビーイングの追求のために、生活の中で身体的、精神的、社会的な側面のつながりを認識し、すべての側面に栄養を与え、心の平安を見つけましょう。",
      KR: "전인적인 웰비잉을 추구하기 위해 삶의 신체적, 정신적 그리고 사회적 측면 사이의관계를 잘 파악하고, 다양한 부분에 있어서 평정심을 유지하도록 해보세요.",
      ZH: "為了支持追求全方位的健康，認識到生活中身體、心靈和社群之間的連結，並滋養所有層面，以找到平衡感。",
      TH: "เพื่อสนับสนุนเส้นทางสู่การมีสุขภาวะที่ดีแบบองค์รวม ขอให้คุณตระหนักว่าด้านต่าง ๆของชีวิตไม่ว่าจะเป็นร่างกาย จิตใจ หรือสังคมล้วนเชื่อมโยงกัน และทะนุบำรุงทุก ๆ ด้านเพื่อพบกับสภาวะสมดุล",
    };


    function GetText1(){
      let text = '';
      if (result == 0){
        text = text1[language]
      }
      else if (result == 1){
        text = text2[language]// "You are defined by your innate ability to build bridges and cultivate a sense of community among those around you. You excel in social settings and actively foster your connections. "
      }
      else if (result == 2){
        text = text3[language]//"Epitomised by a dedication to pursue a fulfilling life. You seek to find balance and wellbeing of your physical, mental, and emotional dimensions, while prioritising harmony and vitality."
      }
      else if (result == 3){
        text = text4[language]//"Remember that spiritual nourishment is valuable and your wellbeing journey can include committed practices that promote health and clarity to the body and mind."
      }
      return text;
    }

    function GetText2(){
      let text = '';
      if (result == 0){
        text = text5[language]
      }
      else if (result == 1){
        text = text6[language]
      }
      else if (result == 2){
        text = text7[language]
      }
      else if (result == 3){
        text = text8[language]
      }
      return text;
    }

    function GetPdf(){
  // Ensure that the result is a number
  const resultNumber = parseInt(result) + 1;

  if (isNaN(resultNumber) || resultNumber < 0) {
    // Handle invalid or negative results here
    return "";
  }

  const lang = language.toString().toLowerCase();

  // Assuming your image files are named as "1.png", "2.png", and so on...
  return `/plan/${lang}/${resultNumber}.pdf`;
    }

    function save(){
      const link = document.createElement('a');
      link.href = GetPdf();
      console.log(link.href);
      link.download = 'result.pdf'; // Specify the file name here
      link.click();
      link.remove();
    }

    function GetNext(){
      if (language == "ENG"){
        return "NEXT";
      }
      else if (language == "JP"){
        return "進む";
      }
      else if (language == "KR"){
        return "다음";
      }
      else if (language == "ZH"){
        return "下一頁";
      }
      else if (language === "TH"){
        return "ถัดไป";
      }
      return "NEXT";
    }

    const youare = {
      ENG: "You are a",
      JP: "あなたは、",
      KR: "게스트님은",
      ZH: "你是",
      TH: "คุณคือ",
    };

    const qr1={
      ENG: "Scan this QR code to receive your",
      JP: "QRコードを読み取ってあなただけの",
      KR: "QR 코드를 스캔하고",
      ZH: "掃描QR Code，獲取屬於你的七日計劃",     
      TH: "สแกนคิวอาร์โค้ดนี้เพื่อดูแผนเพื่อ", 
    }
    const qr2={
      ENG: "personal 7-Day Wellbeing Plan",
      JP: "７日間ウェルビーイングプランをゲットしよう。",
      KR: "여러분에게 꼭 맞는 7-day 플랜을 받으세요",
      ZH: "",      
      TH: "สุขภาวะที่ดีสำหรับ 7 วันของคุณ",
    }

    const viewsave={
      ENG: "View and save your personal 7-day Wellbeing Plan",
      JP: "あなただけの７日間ウェルビーイングプランをゲットしよう",
      KR: "7-day 웰비잉 플랜 보기 및 저장하기",
      ZH: "瀏覽並儲存你的七日計劃",
      TH: "ดูและบันทึกแผนเพื่อสุขภาวะที่ดีสำหรับ 7 วันของคุณ",      
    }

    const saveText={
      ENG: "Save",
      JP: "保存する",
      KR: "저장",
      ZH: "儲存", 
      TH: "บันทึก",
    }
  
    return (



      <div style={divStyle}>
        
        {/* You can add content inside this div if needed */}
        <img src={isLandscape ? '/bg2-landscape.webp' : '/bg2-portrait.webp'} alt="bg" style={backgroundImageStyle} />
        <img src={`/logo/smallfind${language.toLowerCase()}.webp`} alt="Find SVG" className="find-svg" style={findStyle} />


        <NormalContainer top="20%" left={GetPad()} width='80%'>
            <TextWithLine fontSize={isLandscape ? '5vh' : '2vh'} font={language} text={youare[language]} />
            <MyText font={language} fontSize={isLandscape ? '5vh' : '3vh'}fontWeight='bold' text={GetRole()}></MyText>
            <div style={{ height: isLandscape ? '5vh' : '5vh' }}></div>
            <MyText font={language} fontSize={isLandscape ? '3vh' : '2vh'} text={GetText1()}></MyText>
            <div style={{ height: isLandscape ? '5vh' : '5vh' }}></div>
            <MyText font={language} fontSize={isLandscape ? '3vh' : '2vh'} text={GetText2()}></MyText>

            {!isLandscape &&<div style={{ height: isLandscape ? '5vh' : '5vh' }}></div>}
            {!isLandscape &&<MyText font={language} fontSize={isLandscape ? '3vh' : '1.5vh'} text={viewsave[language]}></MyText>}
            {!isLandscape &&<div style={{ height: isLandscape ? '1vh' : '1vh' }}></div>}
            {!isLandscape &&<CustomButton onClick={save} icon='/download.svg' fontSize='2vh' font={language} padding='0.5vh 3vh 0.5vh 3vh' position='static' >{saveText[language]}</CustomButton>}

            {isLandscape &&<div style={{ paddingTop:'6vh', display: 'flex', justifyContent: 'normal' }}>
            {/* Column 1 */}
            <div style={{  flexDirection: 'column', marginRight: '1vw', }}>
            <MyText font={language} fontSize={isLandscape ? '2.5vh' : '1.5vh'} text={qr1[language]}></MyText>
            <br/>
              <MyText font={language} fontSize={isLandscape ? '2.5vh' : '1.5vh'} text={qr2[language]}></MyText>
            </div>

            {/* Column 2 */}
            <div style={{ flex: '1', textAlign: 'left', }}>
              <QRCode  
              bgColor='transparent'
              fgColor='white'
              style={{ height: "auto", width: "12vh" }}
              value={`discover.lululemonfindyourwellbeing.com/plan/${language.toLowerCase()}/${(result+1)}.pdf`}
              >

                
              </QRCode>
 
            </div>
          </div>}


        </NormalContainer>






     
        <CustomText onClick={handleClick}  textAlign='right'   font={language} text={GetNext()} fontSize={isLandscape ? '2vw' : '2vh'} right={GetPad()} top={isLandscape? '90%' : '90%'}/> 
 
  
  
  
      </div>


    );
  };
  
  export default PlanPage;