import React, { useState } from 'react';
import { useMediaQuery } from 'react-responsive';
import CustomText from './CustomText';
import CustomButton from './CustomButton';
import GridContainer from './Grid';
import NormalContainer from './NormalContainer';
import Div100vh, { use100vh } from 'react-div-100vh';
import MyText from './MyText';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import './QuestionPageBar.css';
import SwiperText from './SwiperText';


const QuestionPageBar = ({language, endText='NEXT', setAns, landscapeWidth = '85vw', portraitWidth = '77vw', question, extraInstruct, ans = [], prevPage, nextPage, selectedAns, questionNo = 1 }) => {

    const next = () => {
        if (activeButton === -1)
            return;
        nextPage();
        //alert('Button clicked!');
      };

      const prev = () => {
        prevPage();
        //alert('Button clicked!');
      };
    
    const isLandscape = useMediaQuery({ query: '(orientation: landscape)' });
    const divStyle = {

      width: '100%',
      height: '100%',
      position: 'absolute'
      
  };
  
    const backgroundImageStyle = {

      width: '100%',
      height: '100%',

    };
  
    function GetPad(){
      return isLandscape ? '7%' : '10%'
    }

    function GetAgree(){
      if (language == "ENG"){
        return "AGREE";
      }
      else if (language == "JP"){
        return "そう思う";
      }
      else if (language == "ZH"){
        return "同意";
      }
      else if (language == "KR"){
        return "동의";
      }
      else if (language === "TH"){
        return "เห็นด้วย";
      }
      return "AGREE";
    }

    function GetDisagree(){
      if (language == "ENG"){
        return "DISAGREE";
      }
      else if (language == "JP"){
        return "そう思わない";
      }
      else if (language == "ZH"){
        return "不同意";
      }
      else if (language == "KR"){
        return "비동의";
      }
      else if (language === "TH"){
        return "ไม่เห็นด้วย";
      }
      return "DISAGREE";
    }
  
  
    const findStyle = {
      height: isLandscape ? '8vh' : '3.8vh',
      position: 'absolute', // To position the Find SVG within the container
      top: isLandscape ? '12%' : '7%', // Vertically center the Find SVG
      right: '0%', // Place the Find SVG 10% from the left
      transform: 'translateY(-50%)', // Center the Find SVG vertically
      padding: GetPad(),
    };
  
    const [activeButton, setActiveButton] = useState(selectedAns);

    const handleClick = (i) => {
        setActiveButton(i);
        setAns(questionNo, i);
    };
  
    function GetButTextSize(){
      return isLandscape ? '3.5vh' : '2vh'
    }

    function GetPrev(){
      if (language == "ENG"){
        return "PREV";
      }
      else if (language == "JP"){
        return "戻る";
      }
      else if (language == "KR"){
        return "이전";
      }
      else if (language == "ZH"){
        return "上一頁";
      }
      else if (language == "TH"){
        return "ก่อนหน้า";
      }
      return "PREV";
    }

    function GetNext(){
      if (language == "ENG"){
        if (questionNo == 10){
          return "RESULT"
        }
        return "NEXT";
      }
      else if (language == "JP"){
        if (questionNo == 10){
          return "結果を見る"
        }
        return "進む";
      }
      else if (language == "KR"){
        if (questionNo == 10){
          return "결과"
        }
        return "다음";
      }
      else if (language == "ZH"){
        if (questionNo == 10){
          return "結果"
        }
        return "下一頁";
      }
      else if (language === "TH"){
        if (questionNo === 10){
          return "ผลลัพธ์"
        }
        return "ถัดไป";
      }
      return "NEXT";
    }
  
    return (



      <div style={divStyle}>
        
        {/* You can add content inside this div if needed */}
        <img src={isLandscape ? '/questionbg/q'+questionNo+'-landscape.webp' : '/questionbg/q'+questionNo+'-portrait.webp'} alt="bg" style={backgroundImageStyle} />
        <img src={`/logo/smallfind${language.toLowerCase()}.webp`} alt="Find SVG" className="find-svg" style={findStyle} />

        <NormalContainer width='80vw'  left={GetPad()} top='25%'>
        <MyText  fontWeight='bold' font={language} text={question} fontSize={isLandscape ? (language=='ENG'? '4.2vh' : '3.2vh' ) : (language=='ENG'? '3vh' : '2.5vh' )} />
        {isLandscape && <br></br>}
        <MyText paddingLeft={isLandscape ? '0px' : '1vw'}  font={language} text={extraInstruct} fontSize={isLandscape ? (language=='ENG'? '2.5vh' : '2vh' ) : (language=='ENG'? '2vh' : '1.5vh' )} />




</NormalContainer>
    
        {/* Render a CustomButton for each element in the 'ans' array */}

        {isLandscape&& <GridContainer borderRadius='5vh' border='1px solid white'  columns='auto auto auto auto auto auto auto auto auto auto'  width={isLandscape ? landscapeWidth : portraitWidth}    top={isLandscape ? '40%' : '45%'} left={GetPad() }  gapY={isLandscape ? '4vw' : '2vh'}>      


        
        {ans.map((answer, index) => (
  <CustomButton
    key={index} // Add a unique key prop here
    isBorder={false}
    onClick={() => handleClick(index)}
    isActive={activeButton === index}
    padding={isLandscape ? '1vw' : '1vh'}
    fontSize={GetButTextSize()}
    font="ENG"
  >
    {answer}
  </CustomButton>
))}


</GridContainer>}

{isLandscape&&<CustomText  font={language} text={GetDisagree()} fontSize={isLandscape ? '2vw' : '2vh'} left={GetPad()} top="53%"/>}
{isLandscape&&<CustomText  textAlign='right'   font={language} text={GetAgree()} fontSize={isLandscape ? '2vw' : '2vh'} right={GetPad()} top="53%"/> }


{!isLandscape &&  <NormalContainer top='40%' left='10%' width='80vw' height='10vh'>

<Swiper 

      slidesPerView={5}

      initialSlide={activeButton}
      
      onSlideChange={(swiper) => {
        handleClick(swiper.activeIndex);
      } }
    >

      <SwiperSlide></SwiperSlide>
      

      
      <SwiperSlide><SwiperText color='#C5AFAB' fontSize='2vh'  horizontalPos='right' width='100%' height='100%' font={language} text={GetDisagree()}></SwiperText></SwiperSlide>
      {[1, 2, 3, 4, 5, 6, 7, 8, 9, 10].map((item, index) => (
        <SwiperSlide key={index}>
          <SwiperText opacity={activeButton===index?'1':'0.5'} fontSize={activeButton===index?'3.5vh':'3vh'}  color={activeButton===index?'white':'#C5AFAB'} width='100%' height='100%' font='ENG' text={item.toString()} />
        </SwiperSlide>
      ))}
      <SwiperSlide><SwiperText color='#C5AFAB' fontSize='2vh' horizontalPos='left' width='100%' height='100%' font={language} text={GetAgree()}></SwiperText></SwiperSlide>
      <SwiperSlide></SwiperSlide>
    </Swiper>
        <div style={{ paddingTop:'2vh', width:'100%', textAlign:'right'}}><img width='30vh' height='auto' src='right-arrow.webp'></img></div>
        

</NormalContainer> }  

<CustomText onClick={prev}   font={language} text={GetPrev()} fontSize={isLandscape ? '3vh' : '2vh'} left={GetPad()} top={isLandscape? '85%' : '90%'}/>
  
<CustomText transform='translateX(-50%)'  font={language} text={questionNo + '/10'} fontSize={isLandscape ? '3vh' : '2vh'} left='50%' top={isLandscape? '85%' : '90%'}/>
  
<CustomText onClick={next}  textAlign='right'   font={language} text={GetNext()} fontSize={isLandscape ? '3vh' : '2vh'} right={GetPad()} top={isLandscape? '85%' : '90%'}/> 
      </div>


      
    );
  };
  
  export default QuestionPageBar;